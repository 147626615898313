import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const RouterLocationWrapper = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }, [location]);
  return <>{props.children}</>;
};

export default RouterLocationWrapper;
