import React from 'react';
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router';

interface ILinkButtonProps {
  label: string;
  url?: string;
  rightArrow?: boolean;
  onClick?: () => void;
}

const LinkButton = (props: ILinkButtonProps) => {
  const rightSideBoxStyle: React.CSSProperties = {
    position: "absolute",
    right: "16px",
    top: "0px",
    bottom: "0px",
    width: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(255, 255, 255, 0.85)"
  };
  return <div className='link-tree-button' onClick={props.onClick}>{props.label}
    <div style={rightSideBoxStyle}>
      {props.rightArrow && <RightOutlined />}
    </div>
  </div>
};

const LinkTreePage = () => {
  const navigate = useNavigate();
  const onGotoInstagramButtonClicked = () => {
    window.open('instagram://user?username=mini_golf_club')
  };
  const onGoToHomeButtonClicked = () => {
    alert('홈페이지 준비 중입니다.')
  };
  const onRecruitButtonClicked = () => {
    navigate('/recruit/select-type')
  };
  return <div style={{ height: '80vh', position: 'relative', margin: "0px auto", maxWidth: "680px", display: 'flex', flexDirection: "column", padding: "72px 27px 32px 27px", justifyContent: 'center' }}>
    {/* Profile Section */}
    <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: "center" }}>
      <img alt="profile"
        style={{ height: "72px", marginBottom: '16px' }}
        src="/minigolf_logo.png"
      />
    </div>
    {/* Links Section */}
    <div style={{ display: 'flex', flexDirection: "column", gap: '16px', marginTop: '12px' }}>
      <LinkButton
        onClick={onGotoInstagramButtonClicked}
        rightArrow
        label='Instagram 앱 화면 바로가기' />
      <LinkButton
        onClick={onGoToHomeButtonClicked}
        rightArrow
        label='홈페이지 바로가기'
      />
      <LinkButton
        onClick={onRecruitButtonClicked}
        rightArrow
        label='미니골프클럽 3기 신청' 
      />
    </div>
    {/* Footer Section */}
    <div style={{
      textAlign: "left", color: "rgba(0,0,0,0.5)", fontSize: "12px", lineHeight: 1.2,
      marginTop: '16px', paddingLeft: '4px'
    }}>
      <span>(문의사항)</span><br />
      <span>Instagram @mini_golf_club</span><br />
      <span>회장 서준원 010-5354-1121 (서울대학교 경영학과 22)</span><br />
      <span>부회장 이주아 010-6612-2387 (서울대학교 언어학과 21)</span>
    </div>

  </div >;
};

export default LinkTreePage;