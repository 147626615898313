import React from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'
import RecruitBigSelectButton from './Atoms/RecruitBigSelectButton'
import { useNavigate } from 'react-router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { recruitInfoData } from './RecruitRecoil';
import { backdropLoadingRecoil } from '../BackdropRecoil';
import axios from 'axios';

const RecruitSelectTrackPage = () => {
  const navigate = useNavigate();
  const [recruitInfoDataValue, setRecruitInfoData] = useRecoilState(recruitInfoData);
  const setBackdropLoading = useSetRecoilState(backdropLoadingRecoil);
  const onBeginnerClicked = () => {
    setRecruitInfoData(prev => ({ ...prev, trackType: "BEGINNER", availableDay: [] }));
    navigate('/recruit/check-all');
  }
  const onIntermediateClicked = () => {
    setRecruitInfoData(prev => ({ ...prev, trackType: "INTERMEDIATE", availableDay: [] }));
    navigate('/recruit/check-all');
  }
  const onAdvancedClicked = () => {
    alert('본인이 상급자라고 생각하시나요? 풉 ㅋ');
  }
  const onAlumniClicked = async () => {
    setBackdropLoading(true);
    await axios.post('https://minigolfapi.owsla.duckdns.org/alumni-recruit', recruitInfoDataValue);
    setBackdropLoading(false);
    navigate('/recruit/alumni-done')
  }
  return (
    <RecruitPageWrapper>
      <div className='recruit-select-type-page-wrapper'>
        {(recruitInfoDataValue.recruitType !== 'OLD' || new Date() < new Date('2024-02-15 00:00:00')) && <>
          <RecruitBigSelectButton onClick={onBeginnerClicked} bigText='초보자 트랙' smallText='아예 못하는 사람이랑 애매하게 못하는 사람 둘 다' />
          <div style={{ height: '16px' }} />
          <RecruitBigSelectButton onClick={onIntermediateClicked} bigText='중급자 트랙' smallText='나는 스크린 게임 18홀 플레이가 가능하다.' />
          <div style={{ height: '16px' }} />
          <RecruitBigSelectButton onClick={onAdvancedClicked} bigText='상급자 트랙' smallText='나는 골프채와 신검합일의 경지에 도달했다.' />
          <div style={{ height: '16px' }} />
        </>}
        {recruitInfoDataValue.recruitType === 'OLD' &&
          <RecruitBigSelectButton onClick={onAlumniClicked} bigText='alumni 톡방 초대' smallText='이번 학기는 함께하지 못하지만, 마음만은 영원히 ㅜ' />}
      </div>
    </RecruitPageWrapper>
  )
}

export default RecruitSelectTrackPage