import React from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'

import RecruitMidSizeButton from './Atoms/RecruitMidSizeButton'
import { useNavigate } from 'react-router';

const RecruitAlumniDone = () => {
  const navigate = useNavigate();
  const onGoToHomeButtonClicked = () => {
    navigate('/');
  };
  return (
    <RecruitPageWrapper noBackButton>
      <div className='recruit-done-page-wrapper'>
        <h1 className='recruit-page-big-title'>신청 완료되었습니다!!</h1>
        <div style={{ height: '16px' }} />
        <h2 className='recruit-done-page-instruction-text'>
          그동안 함께할 수 있음에 감사드립니다.<br />
          톡방 개설 및 운영 방식이 결정나는 대로<br />
          초대해드리겠습니다.<br />
          <br />
          생각이 나시면 언제든 <br />
          미니골프 클럽을 찾아주세요!<br />
          <br />
          *톡방에서는 번개 라운딩, 스크린 등 <br />
          동아리 활동과는 별개로 친목활동이<br />
          이어질 예정입니다.
        </h2>
        <div style={{ height: '32px' }} />
        <RecruitMidSizeButton onClick={onGoToHomeButtonClicked} text='홈 화면 돌아가기' />
      </div>
    </RecruitPageWrapper >
  )
}

export default RecruitAlumniDone
