import { Spin } from "antd";
import { useRecoilValue } from "recoil";
import { backdropLoadingRecoil } from "./BackdropRecoil";


const BackdropLoading = () => {
  const backdropLoadingState = useRecoilValue(backdropLoadingRecoil)
  return (
    <>
      {backdropLoadingState && <Spin fullscreen />}
    </>

  );
};
export default BackdropLoading;
