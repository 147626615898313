import React from 'react'

interface IMidSizeButtonProps {
  text: string;
  isBottomButton?: boolean;
  backgroundColor?: string;
  onClick?: () => void;
  deactivated?: boolean;
}

const RecruitMidSizeButton = (props: IMidSizeButtonProps) => {
  return (
    <div onClick={props.onClick}
      className='recruit-page-mid-size-button' style={props.isBottomButton ? {
        position: 'fixed',
        bottom: '24px',
        left: '24px',
        right: '24px',
        ...(props.deactivated ? {
          backgroundColor: 'rgba(162,162,162,1)',
          color: 'rgba(255,255,255,0.5)'
        } : {})
      } : { width: '100%' }}>
      {props.text}
    </div>
  )
}

export default RecruitMidSizeButton