import React from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'
import { Input, notification } from 'antd'
import RecruitMidSizeButton from './Atoms/RecruitMidSizeButton'
import { useNavigate } from 'react-router'
import { recruitInfoData } from './RecruitRecoil'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { backdropLoadingRecoil } from '../BackdropRecoil'
import axios from 'axios'
import { NotificationPlacement } from 'antd/es/notification/interface'

const RecruitSimpleFormPage = () => {
  const navigate = useNavigate();
  const [recruitInfoDataValue, setRecruitInfoData] = useRecoilState(recruitInfoData);
  const setBackdropLoading = useSetRecoilState(backdropLoadingRecoil);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message: string) => {
    api.open({
      message: <span>{message}<br />
        {recruitInfoDataValue.name}님 반갑습니다!</span>
    });
  };
  const onNextButtonClicked = async () => {
    // Validation
    if (recruitInfoDataValue.name === "" || recruitInfoDataValue.phoneNumber === "") {
      return;
    }
    if (recruitInfoDataValue.name.length < 2 || recruitInfoDataValue.name.length > 4) {
      alert('이름이 올바르지 않습니다.')
      return;
    }
    if (recruitInfoDataValue.phoneNumber.length !== 11) {
      alert('휴대전화번호 형식이 올바르지 않습니다.')
      return;
    }

    // ! Case 1. 기존 유저
    if (recruitInfoDataValue.recruitType === 'OLD') {
      setBackdropLoading(true)
      try {
        const { data } = await axios.post('https://minigolfapi.owsla.duckdns.org/check-member', {
          name: recruitInfoDataValue.name,
          phoneNumber: recruitInfoDataValue.phoneNumber
        });
        openNotification(data.message)

        const timer = () => new Promise(resolve => setTimeout(resolve, 2000));
        await timer();
        navigate('/recruit/detail-form');
      } catch (error) {
        alert('유저 정보를 찾을 수 없습니다.')
      }
      finally {
        setBackdropLoading(false)
      }
      return;
    }

    // ! Case 2. 신규 유저
    navigate('/recruit/detail-form');
  }
  const onNameChanged = (e: any) => {
    setRecruitInfoData(prev => ({ ...prev, name: e.target.value }));
  }

  const onPhoneNumberChanged = (e: any) => {
    setRecruitInfoData(prev => ({ ...prev, phoneNumber: e.target.value.replace('-', '') }));
  }
  return (
    <RecruitPageWrapper>
      {contextHolder}
      <div className='recruit-form-page-wrapper'>
        <h1 className='recruit-page-big-title'>기본 정보 입력</h1>
        <div style={{ height: '8px' }} />
        <h2 style={{ paddingLeft: '4px' }} className='recruit-page-small-title'>이름*</h2>
        <div style={{ height: '2px' }} />
        <Input
          style={{ border: '1px solid rgba(0,0,0,0.1)' }}
          placeholder='팜하니' id="name" onChange={onNameChanged} type='text' className='recruit-page-input' value={recruitInfoDataValue.name} />
        <div style={{ height: '8px' }} />
        <h2 style={{ paddingLeft: '4px' }} className='recruit-page-small-title'>전화번호*</h2>
        <div style={{ height: '2px' }} />
        <Input style={{ border: '1px solid rgba(0,0,0,0.1)' }} placeholder='01012345678' id="phoneNumber" onChange={onPhoneNumberChanged} type='text' className='recruit-page-input' value={recruitInfoDataValue.phoneNumber} />
        <div style={{ height: '2px' }} />
        <h2 className='recruit-page-small-information'>*는 필수 입력 사항입니다.</h2>
        {/* Bottom */}
        <RecruitMidSizeButton
          deactivated={
            recruitInfoDataValue.name === "" || recruitInfoDataValue.phoneNumber === ""
          }
          onClick={onNextButtonClicked} text='다음' isBottomButton />
      </div>
    </RecruitPageWrapper>
  )
}

export default RecruitSimpleFormPage
