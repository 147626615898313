import React from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'
import RecruitMultiSelectButton from './Atoms/RecruitMultiSelectButton'
import RecruitMidSizeButton from './Atoms/RecruitMidSizeButton'
import { useNavigate } from 'react-router'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { recruitInfoData } from './RecruitRecoil'
import axios from 'axios'
import { backdropLoadingRecoil } from '../BackdropRecoil'

const RecruitSelectFavoriteDayPage = () => {
  const navigate = useNavigate();
  const [recruitInfoDataValue, setRecruitInfoData] = useRecoilState(recruitInfoData);
  const setBackdropLoading = useSetRecoilState(backdropLoadingRecoil);
  const onFinalButtonClicked = async () => {
    if (recruitInfoDataValue.favoriteDay === "") {
      return;
    }
    // 시작
    setBackdropLoading(true);
    await axios.post('https://minigolfapi.owsla.duckdns.org/recruit', recruitInfoDataValue);
    setBackdropLoading(false);
    navigate('/recruit/done');
  }
  const onMultiSelectButtonClicked = (buttonText: string) => {
    if (recruitInfoDataValue.favoriteDay === buttonText) {
      setRecruitInfoData(prev => ({ ...prev, favoriteDay: "" }))
    } else {
      setRecruitInfoData(prev => ({ ...prev, favoriteDay: buttonText }));
    }
  }

  return (
    <RecruitPageWrapper>
      <div className='recruit-select-day-page-wrapper'>
        <h1 className='recruit-page-big-title'>선호 요일 선택(택일)</h1>
        {recruitInfoDataValue.availableDay.map((e, idx) =>
          <RecruitMultiSelectButton key={idx} text={e}
            colorWhenActivated='rgba(255, 0,0,0.6)'
            onClick={() => onMultiSelectButtonClicked(e)}
            isActivated={recruitInfoDataValue.favoriteDay.includes(e)} />)}
        {/* Bottom */}
        <RecruitMidSizeButton deactivated={recruitInfoDataValue.favoriteDay === ""} onClick={onFinalButtonClicked} text='이제 골프 치러 가요!' isBottomButton />
      </div>
    </RecruitPageWrapper>
  )
}


export default RecruitSelectFavoriteDayPage