import React from 'react'
import { RightOutlined } from "@ant-design/icons";

interface IBigSelectButtonProps {
  bigText: string;
  isBottomButton?: boolean;
  smallText: string;
  onClick?: () => void;
}

const RecruitBigSelectButton = (props: IBigSelectButtonProps) => {
  return (
    <div className='recruit-page-big-select-button' onClick={props.onClick} style={props.isBottomButton ? {
      position: 'fixed',
      bottom: '32px',
      left: '24px',
      right: '24px',
      boxSizing: 'unset',
      width: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    } : {}}>
      <h1>{props.bigText}</h1>
      <h2>{props.smallText}</h2>
      <RightOutlined style={{ position: 'absolute', right: '16px', color: 'rgba(0,0,0,0.7)' }} />
    </div>
  )
}

export default RecruitBigSelectButton