import React from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'
import RecruitMidSizeButton from './Atoms/RecruitMidSizeButton'
import { useNavigate } from 'react-router';
import { recruitInfoData } from './RecruitRecoil';
import { useRecoilState } from 'recoil';
import { Input, Radio } from 'antd';

const RecruitDetailFormPage = () => {
  const navigate = useNavigate();
  const [recruitInfoDataValue, setRecruitInfoData] = useRecoilState(recruitInfoData);
  const onNextButtonClicked = () => {
    // Validation
    if (recruitInfoDataValue.age === "" ||
      recruitInfoDataValue.univerityName === "" ||
      recruitInfoDataValue.major === "") {
      return;
    }
    if (recruitInfoDataValue.age.length !== 4) {
      alert('나이를 생년 4자리로 입력해주세요.\nex) 00년생 -> 2000')
      return;
    }

    navigate('/recruit/select-track');
  }
  const onGenderChanged = (e: any) => {
    setRecruitInfoData(prev => ({ ...prev, gender: e.target.value }));
  }
  const onAgeChanged = (e: any) => {
    setRecruitInfoData(prev => ({ ...prev, age: e.target.value }));
  }
  const onUniversityNameChanged = (e: any) => {
    setRecruitInfoData(prev => ({ ...prev, univerityName: e.target.value }));
  }
  const onMajorChanged = (e: any) => {
    setRecruitInfoData(prev => ({ ...prev, major: e.target.value }));
  }
  const onFriendNameChanged = (e: any) => {
    setRecruitInfoData(prev => ({ ...prev, friendName: e.target.value }));
  }


  return (
    <RecruitPageWrapper>
      <div className='recruit-form-page-wrapper'>
        <h1 className='recruit-page-big-title'>상세 정보 입력</h1>
        <div style={{ height: '8px' }} />
        <h2 style={{ paddingLeft: '4px' }} className='recruit-page-small-title'>성별*</h2>
        <div style={{ height: '2px' }} />
        <Radio.Group style={{ paddingLeft: '4px' }} onChange={onGenderChanged} value={recruitInfoDataValue.gender} >
          <Radio value="MALE">남성</Radio>
          <Radio value="FEMALE">여성</Radio>
        </Radio.Group>
        <div style={{ height: '8px' }} />
        <h2 style={{ paddingLeft: '4px' }} className='recruit-page-small-title'>나이(태어난 연도)*</h2>
        <div style={{ height: '2px' }} />
        <Input placeholder='2000' onChange={onAgeChanged} type='text' className='recruit-page-input' value={recruitInfoDataValue.age} />
        <div style={{ height: '8px' }} />
        <h2 style={{ paddingLeft: '4px' }} className='recruit-page-small-title'>소속 대학교*</h2>
        <div style={{ height: '2px' }} />
        <Input placeholder="OO대학교" onChange={onUniversityNameChanged} type='text' className='recruit-page-input' value={recruitInfoDataValue.univerityName} />
        <div style={{ height: '8px' }} />
        <h2 style={{ paddingLeft: '4px' }} className='recruit-page-small-title'>소속 학과*</h2>
        <div style={{ height: '2px' }} />
        <Input placeholder='OO학과' onChange={onMajorChanged} type='text' className='recruit-page-input' value={recruitInfoDataValue.major} />
        <div style={{ height: '8px' }} />
        <h2 style={{ paddingLeft: '4px' }} className='recruit-page-small-title'>동반 지원자</h2>
        <div style={{ height: '2px' }} />
        <Input placeholder='함께 지원한 친구가 있다면 적어주세요 :)' onChange={onFriendNameChanged} type='text' className='recruit-page-input' value={recruitInfoDataValue.friendName} />
        <div style={{ height: '8px' }} />
        <h2 className='recruit-page-small-information'>*는 필수 입력 사항입니다.</h2>
        <div style={{ height: '4px' }} />
        {/* Bottom */}
        <RecruitMidSizeButton
          deactivated={
            recruitInfoDataValue.age === "" ||
            recruitInfoDataValue.univerityName === "" ||
            recruitInfoDataValue.major === ""
          }
          onClick={onNextButtonClicked}
          text='다음' isBottomButton />
      </div>
    </RecruitPageWrapper>
  )
}

export default RecruitDetailFormPage