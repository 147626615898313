import { atom } from "recoil";
interface IRecruitInfoData {
  recruitType: "NONE" | "OLD" | "NEW";

  name: string;
  phoneNumber: string;
  gender: "NONE" | "MALE" | "FEMALE";
  age: string;
  univerityName: string;
  major: string;
  friendName: string;
  trackType: "NONE" | "BEGINNER" | "INTERMEDIATE" | "ADVANCED";
  availableDay: string[];
  favoriteDay: string;

  isFromIntermediateRecruit?: boolean;
  isFromBeginnerRecruit?: boolean;
}

export const recruitInfoData = atom<IRecruitInfoData>({
  key: 'recruitInfoData',
  default: {
    recruitType: "NONE",

    name: "",
    phoneNumber: "",
    gender: "MALE",
    age: "",
    univerityName: "",
    major: "",
    friendName: "",
    trackType: "NONE",
    availableDay: [],
    favoriteDay: ""
  }
});