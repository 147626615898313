import React from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'
import RecruitBigSelectButton from './Atoms/RecruitBigSelectButton'
import { useNavigate } from 'react-router'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { recruitInfoData } from './RecruitRecoil'
import { backdropLoadingRecoil } from '../BackdropRecoil'
import axios from 'axios'

const RecruitSelectTypePage = () => {
  const navigate = useNavigate();
  const setRecruitInfoData = useSetRecoilState(recruitInfoData);

  const onExistUserButtonClicked = () => {
    setRecruitInfoData(prev => ({ ...prev, recruitType: "OLD" }));
    navigate('/recruit/simple-form');
  }
  const onNewUserButtonClicked = () => {
    setRecruitInfoData(prev => ({ ...prev, recruitType: "NEW" }));
    navigate('/recruit/simple-form');
  }
  const onCheckProgressButtonClicked = () => {
    navigate('/recruit/current-result');
  }

  return (
    <RecruitPageWrapper>
      <div className='recruit-select-type-page-wrapper'>
        <RecruitBigSelectButton onClick={onExistUserButtonClicked} bigText='기존 부원용' smallText={
          new Date() < new Date('2024-02-15 00:00:00') ?
            '3기 부원 신청 2/14 23:59 마감' : '3기 부원 신청 마감. 알럼나이 톡방 신청'} />

        <div style={{ height: '16px' }} />
        <RecruitBigSelectButton onClick={onNewUserButtonClicked}
          bigText='신규 부원용' smallText='미니골프클럽이 처음이신가요? 반가워요 후후 😈' />
        <div style={{ height: '16px' }} />
        <RecruitBigSelectButton onClick={onCheckProgressButtonClicked}
          bigText='지원 현황 / 결과 확인하기' smallText='2/28 중 2차 발표' />
      </div>
    </RecruitPageWrapper>
  )
}

export default RecruitSelectTypePage