import React, { useState } from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'
import { Button, Checkbox, Modal } from 'antd';

import RecruitMidSizeButton from './Atoms/RecruitMidSizeButton'
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { recruitInfoData } from './RecruitRecoil';
import { RightOutlined } from "@ant-design/icons";

const RecruitCheckAllPage = () => {
  const navigate = useNavigate();
  const [openedModal, setOpenedModal] = useState("");
  const recruitInfoDataValue = useRecoilValue(recruitInfoData)
  const [checked, setChecked] = useState(false);
  const onNextButtonClicked = () => {
    if (!checked) {
      return;
    }
    navigate('/recruit/select-available-day');
  }
  return (
    <RecruitPageWrapper>
      <div className='recruit-check-all-page-wrapper'>
        <h1 className='recruit-page-big-title'>상세 정보 확인</h1>
        <h2 style={{ paddingLeft: "8px" }} className='recruit-page-small-information' >*는 회비에 포함된 기본 활동입니다. 나머지는 자율</h2>

        {/* Middle */}
        <div className='recruit-page-frame-section'>
          {/* // ! 초보자 */}
          {recruitInfoDataValue.trackType === "BEGINNER" && <>
            <span className='recruit-page-frame-text-big'>초보자 트랙</span><br />
            <span className='recruit-page-frame-text-small'>
              레슨 8회*<br />
              토(19-21시), 금(9-11시), 일(8-10시) 중 8회 조율 예정<br />
              장소: 여의도 핀하이 골프랩. / KPGA,KPLGA 프로 섭외<br />
              스크린 게임 연습 1회*<br />
              인도어 연습 1회*<br />
              스크린 게임<br />
              프로 동반 필드 레슨<br />
              최종 라운딩
            </span>
            <div style={{ height: '4px' }} />
            <Button style={{
              fontSize: '12px',
              fontWeight: 'bold',
              padding: '4px 8px 4px 10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              position: 'absolute', right: '12px', bottom: '12px'
            }} onClick={() => setOpenedModal("BEGINNER")}>
              상세정보
              <RightOutlined style={{ fontSize: 8, marginLeft: '4px' }} />
            </Button>
            {/* // ? 초보자 상세 정보 */}
            <Modal
              title="상세 정보"
              centered
              open={openedModal === "BEGINNER"}
              onOk={() => setOpenedModal("")}
              onCancel={() => setOpenedModal("")}
              cancelButtonProps={{ style: { display: 'none' } }}>
              <p>
                *기본활동(회비 포함): 10주차 커리큘럼<br />
                <br />
                1. 레슨<br />
                - 내용: 골프를 전혀 모르는 입문자(기초 커리큘럼)들과 레슨을 필요로 하는 유경험자(원포인트)들을 위한 개인레슨<br />
                - 장소: 여의도 핀하이 골프랩(여의도역 도보 3분, 영등포구 여의대로 56 한화손해보험 지하1층)<br />
                - 일시:<br />
                토(19-21시), 금(9-11시), 일(8-10시) 중 8회 조율 예정<br />
                1 ~ 4주차: 4회 / 6 ~ 9주차: 4회 (*공휴일 및 시험기간을 제외한 활동주차)<br />
                - 강사: KPGA / KLPGA 소속 프로 (@teampinhigh 팀핀하이 소속 프로)<br />
                <br />
                2. 스크린 게임 연습<br />
                - 내용: 스크린 골프의 기초부터 실전까지 배울 수 있는 프로그램<br />
                - 장소 및 일시: 5주차, 부원들과 조율 예정<br />
                <br />
                3. 인도어 연습<br />
                - 내용: 라운딩 대비 야외 인도어 골프장 연습<br />
                - 장소 및 일시: 10주차, 부원들과 조율 예정<br />
                <br />
                *자율 활동(비용 별도)<br />
                <br />
                1. 스크린 게임<br />
                - 내용: 스크린 게임<br />
                - 장소 및 일시: 부원들과 조율 예정<br />
                - 예상 비용: 회당 2만원<br />
                <br />
                2. 프로 동반 필드레슨<br />
                - 내용: 프로님과 동행하여 파3 9홀을 돌며 필드 레슨을 받는 프로그램<br />
                - 장소 및 일시: 부원들과 조율 예정<br />
                - 예상 비용: 30만원<br />
                <br />
                3. 최종 라운딩<br />
                - 내용: 한 학기 동안 열심히 배운 실력을 바탕으로 스스로 첫 18홀 라운딩 도전<br />
                - 일시: 기말고사 이후 조율 예정<br />
                - 예상 비용: 30만원<br />
              </p>
            </Modal>
          </>}

          {/* // ! 중급자 */}
          {recruitInfoDataValue.trackType === "INTERMEDIATE" && <>
            <span className='recruit-page-frame-text-big'>중급자 트랙</span><br />
            <span className='recruit-page-frame-text-small'>
              스크린 게임 10회*<br />
              원포인트 레슨<br />
              프로 동반 필드 레슨<br />
              라운딩
            </span>
            <div style={{ height: '4px' }} />
            <Button style={{
              fontSize: '12px',
              fontWeight: 'bold',
              padding: '4px 8px 4px 10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              position: 'absolute', right: '12px', bottom: '12px'
            }} onClick={() => setOpenedModal("INTERMEDIATE")}>
              상세정보
              <RightOutlined style={{ fontSize: 8, marginLeft: '4px' }} />
            </Button>
            {/* // ? 중급자 상세 정보 */}
            <Modal
              title="상세 정보"
              centered
              open={openedModal === "INTERMEDIATE"}
              onOk={() => setOpenedModal("")}
              onCancel={() => setOpenedModal("")}
              cancelButtonProps={{ style: { display: 'none' } }}>
              <p>
                *기본활동(회비 포함): 10주차 커리큘럼<br />
                <br />
                1. 스크린 게임<br />
                - 내용: 스크린 게임<br />
                - 장소 및 일시:<br />
                (1) 목요일 성신여대입구역 19-21시<br />
                (서울 성북구 동소문로 106 유타몰 13층)<br />
                (2) 목요일 서울대입구역 19-21시<br />
                (서울 관악구 관악로15길 23-11 관악맥스텔 B1 카카오스크린골프)<br />
                (3) 금요일 영등포구청역 19-21시<br />
                (서울 영등포구 선유로 76 동국메뜨리앙 7층)<br />
                (4) 금요일 서울대입구역 19-21시<br />
                (서울 관악구 관악로15길 23-11 관악맥스텔 B1 카카오스크린골프)<br />
                <br />
                中 배정된 요일에서 총 10주간 스크린 골프 활동을 진행합니다(공휴일 및 시험 기간 제외).<br />
                <br />
                *자율 활동(비용 별도)<br />
                <br />
                1. 원포인트 레슨<br />
                - 내용: 원포인트 레슨이 필요한 부원들을 위한 일회성 레슨<br />
                - 장소: 여의도 핀하이 골프랩(여의도역 도보 3분, 영등포구 여의대로 56 한화손해보험 지하1층)<br />
                - 일시: 금요일 9-11시, 토요일 19-21시, 일요일 8-10시 중 조율 예정<br />
                - 강사: KPGA / KLPGA 소속 프로 (@teampinhigh 팀핀하이 소속 프로)<br />
                <br />
                2. 프로 동반 필드레슨<br />
                - 내용: 프로님과 동행하여 파3 9홀을 돌며 필드 레슨을 받는 프로그램<br />
                - 장소 및 일시: 부원들과 조율 예정<br />
                - 예상 비용: 30만원<br />
                <br />
                3. 라운딩<br />
                - 내용: 부원들과 필드 출격<br />
                - 장소 및 일시: 부원들과 조율 예정<br />
                - 예상 비용: 30만원<br />
              </p>
            </Modal>
          </>}
        </div>
        {/* // ! 추가 정보 */}
        <div className='recruit-page-frame-section'>
          <span className='recruit-page-frame-text-big'>추가 활동</span><br />
          <span className='recruit-page-frame-text-small'>
            OT 및 개강총회*<br />
            MT<br />
            추가 프로 동반 필드 레슨<br />
            프로 동반 해외 라운딩
          </span>
          <div style={{ height: '4px' }} />
          <Button
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              padding: '4px 8px 4px 10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              position: 'absolute', right: '12px', bottom: '12px'
            }}
            onClick={() => setOpenedModal("EXTRA")}>
            상세정보
            <RightOutlined style={{ fontSize: 8, marginLeft: '4px' }} />
          </Button>
          {/* // ? 추가활동 상세 정보 */}
          <Modal
            title="상세 정보"
            centered
            open={openedModal === "EXTRA"}
            onOk={() => setOpenedModal("")}
            onCancel={() => setOpenedModal("")}
            cancelButtonProps={{ style: { display: 'none' } }}>
            <p>
              *기본활동(회비 포함): OT 및 개강총회<br />
              <br />
              1. OT 및 개강총회<br />
              - 내용: 오리엔테이션 및 저녁 식사<br />
              - 장소 및 일시: 3월 9일(토) 저녁 7시 강남역 근처 파티룸 예정<br />
              <br />
              *자율 활동(비용 별도)<br />
              <br />
              1. MT<br />
              - 내용: 동아리 MT<br />
              - 장소 및 일시: 부원들과 조율 예정(5월 예상)<br />
              - 예상 비용: 5만원<br />
              <br />
              2. 추가 프로 동반 필드 레슨<br />
              - 내용: 프로님과 동행하여 필드 레슨을 받는 프로그램<br />
              - 장소 및 일시: 부원들과 조율 예정<br />
              - 예상 비용: 30만원<br />
              <br />
              3. 프로 동반 해외 라운딩<br />
              - 내용: 프로님과 동행하여 태국 등 해외 필드 전지훈련<br />
              - 일시: 부원들과 조율 예정<br />
              - 예상 비용: 60만원(4박5일 경비 별도)<br />
            </p>
          </Modal>
        </div>
        {/* // ! 회비 */}
        {recruitInfoDataValue.trackType === "BEGINNER" && <div className='recruit-page-frame-section'>
          <span className='recruit-page-frame-text-big'>회비: 30만 원</span>
        </div>}
        {recruitInfoDataValue.trackType === "INTERMEDIATE" && <div className='recruit-page-frame-section'>
          <span className='recruit-page-frame-text-big'>회비: 15만 원</span>
        </div>}
        <div className='recruit-page-frame-section'>
          <span className='recruit-page-frame-text-small'>배정된 요일 및 장소에서 위에 제시된 기본 활동에 참여할 기회를 준비할 것을 약속드립니다. 다만, 모든 비용은 선금으로 지불하기 때문에 회비 납부 이후에는 환불이 어렵다는 점 알려드립니다.</span>
        </div>
        <Checkbox onChange={(e) => setChecked(e.target.checked)}>위 내용을 확인하였습니다.</Checkbox>
        <div style={{ height: '56px' }} />
        {/* Bottom */}
        <RecruitMidSizeButton deactivated={!checked} onClick={onNextButtonClicked} text='다음' isBottomButton />
      </div>
    </RecruitPageWrapper>
  )
}

export default RecruitCheckAllPage
