import React from "react";
import RecruitPageWrapper from "./RecruitPageWrapper";
import RecruitBigSelectButton from "./Atoms/RecruitBigSelectButton";
import { useNavigate } from "react-router";

const RecruitCurrentResultPage = () => {
  const navigate = useNavigate();
  const onGobackButtonClicked = () => {
    navigate("/recruit/select-type");
  };
  return (
    <RecruitPageWrapper>
      <div className="recruit-select-type-page-wrapper">
        <h1 style={{ alignSelf: "flex-start" }} className="recruit-page-big-title">
          결과 발표
        </h1>
        <div style={{ height: "16px" }} />
        <div
          className="recruit-page-frame-section"
          style={{ minHeight: "160px", display: "flex", alignItems: "center", justifyContent: "left", padding: "32px" }}>
          <span
            className="recruit-page-frame-text-big"
            style={{
              textAlign:"left"
            }}>
            [2024.02.27 23:59]
            <br />
            2차 모집 지원 마감
            <br />
            <br />
            [2024.02.28]
            <br />
            2차 모집 합격 결과 발표
            <br />
          </span>
        </div>
        <div style={{ height: "24px" }} />
        <h1 style={{ alignSelf: "flex-start" }} className="recruit-page-big-title">
          지원 현황
        </h1>
        <div style={{ height: "16px" }} />
        <div
          className="recruit-page-frame-section"
          style={{ minHeight: "160px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <span className="recruit-page-frame-text-big">지원 현황 집계 중입니다.</span>
          <br />
        </div>
        <div style={{ height: "24px" }} />
        <RecruitBigSelectButton
          // isBottomButton
          onClick={onGobackButtonClicked}
          bigText="다시 지원하기"
          smallText="지원 내용에 수정사항이 생겼다면?"
        />
      </div>
    </RecruitPageWrapper>
  );
};

export default RecruitCurrentResultPage;
