import React from 'react'

interface IMultiSelectButtonProps {
  text: string;
  isActivated?: boolean;
  onClick?: () => void;
  colorWhenActivated?: string;
}

const RecruitMultiSelectButton = (props: IMultiSelectButtonProps) => {
  return (
    <div className='recruit-page-multi-select-button' onClick={props.onClick}
      style={props.isActivated ? { backgroundColor: props.colorWhenActivated ? props.colorWhenActivated : "rgba(0,0,0,0.5)" } : {}}>
      {props.text}
    </div>
  )
}

export default RecruitMultiSelectButton