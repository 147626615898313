import React from "react";
import RecruitPageWrapper from "./RecruitPageWrapper";
import { Checkbox } from "antd";

import RecruitMidSizeButton from "./Atoms/RecruitMidSizeButton";
import { useNavigate } from "react-router";
import { recruitInfoData } from "./RecruitRecoil";
import { useRecoilState } from "recoil";

const RecruitDonePage = () => {
  const navigate = useNavigate();
  const [recruitInfoDataValue, setRecruitInfoData] = useRecoilState(recruitInfoData);
  const onGoToHomeButtonClicked = () => {
    navigate("/");
  };
  const onGoToCurrentResultButtonClicked = () => {
    navigate("/recruit/current-result");
  };
  const onCrossTrackRequestClicked = () => {
    if (recruitInfoDataValue.trackType === "BEGINNER") {
      setRecruitInfoData((prev) => ({ ...prev, trackType: "INTERMEDIATE", isFromBeginnerRecruit: true, availableDay: [] }));
    } else {
      setRecruitInfoData((prev) => ({ ...prev, trackType: "BEGINNER", isFromIntermediateRecruit: true, availableDay: [] }));
    }
    navigate("/recruit/check-all");
  };
  return (
    <RecruitPageWrapper noBackButton>
      <div className="recruit-done-page-wrapper">
        <h1 className="recruit-page-big-title">지원 완료되었습니다!!</h1>
        <div style={{ height: "16px" }} />
        <h2 className="recruit-done-page-instruction-text">
          2차 모집 합격발표는 <span style={{ color: "red" }}>2/28(수)</span> 중
          <br />
          (카카오톡/문자)로 발표될 예정입니다.
        </h2>
        <div style={{ height: "16px" }} />
        <h2 className="recruit-done-page-instruction-text">이후 회비를 납부하시면 최종 확정됩니다.</h2>
        <div style={{ height: "16px" }} />
        <h2 className="recruit-done-page-instruction-text">
          자세한 내용 및 동아리 소식은
          <br />
          인스타그램(@mini_golf_club)에
          <br />
          업데이트될 예정입니다
        </h2>
        <div style={{ height: "16px" }} />
        <h2 className="recruit-done-page-instruction-text">
          문의사항은
          <br />
          인스타그램 DM or
          <br />
          회장 서준원 01053541121
          <br />
          부회장 이주아 01066122387
          <br />
          로 연락 부탁드립니다.
          <br />
        </h2>
        <div style={{ height: "32px" }} />
        <RecruitMidSizeButton onClick={onGoToHomeButtonClicked} text="홈 화면 돌아가기" />
        <div style={{ height: "16px" }} />
        <RecruitMidSizeButton onClick={onGoToCurrentResultButtonClicked} text="실시간 지원 현황 확인하기" />
        {!(recruitInfoDataValue.isFromBeginnerRecruit || recruitInfoDataValue.isFromIntermediateRecruit) && (
          <>
            <div style={{ height: "16px" }} />
            <RecruitMidSizeButton
              onClick={onCrossTrackRequestClicked}
              text={recruitInfoDataValue.trackType === "BEGINNER" ? `중급자 트랙 동시 지원하기` : `초급자 트랙 동시 지원하기`}
            />
          </>
        )}
      </div>
    </RecruitPageWrapper>
  );
};

export default RecruitDonePage;
