import React from 'react'
import RecruitPageWrapper from './RecruitPageWrapper'
import RecruitMultiSelectButton from './Atoms/RecruitMultiSelectButton'
import RecruitMidSizeButton from './Atoms/RecruitMidSizeButton'
import { useNavigate } from 'react-router'
import { recruitInfoData } from './RecruitRecoil'
import { useRecoilState } from 'recoil'

const RecruitSelectAvailableDayPage = () => {
  const navigate = useNavigate();
  const [recruitInfoDataValue, setRecruitInfoData] = useRecoilState(recruitInfoData);
  const onDayButtonClicked = (buttonText: string) => {
    // 뉴욕
    if (buttonText === '토요일 뉴욕 (오후 7:00 ~ 9:00)') {
      alert('준비 중입니다.')
      return;
    }

    if (recruitInfoDataValue.availableDay.includes(buttonText)) {
      setRecruitInfoData(prev => ({ ...prev, availableDay: prev.availableDay.filter(e => e !== buttonText), favoriteDay: "" }));
    }
    else {
      setRecruitInfoData(prev => ({ ...prev, availableDay: [...prev.availableDay, buttonText], favoriteDay: "" }));
    }
  };
  const onNextButtonClicked = () => {
    if (recruitInfoDataValue.availableDay.length === 0) {
      return;
    }
    navigate('/recruit/select-favorite-day');
  }
  return (
    <RecruitPageWrapper>
      <div className='recruit-select-day-page-wrapper'>
        <h1 className='recruit-page-big-title'>가능한 요일 선택(중복)</h1>
        {/* 초보자 경우 */}
        {recruitInfoDataValue.trackType === 'BEGINNER' && [
          '금요일 (오전 9:00 ~ 11:00)',
          '토요일 (오후 7:00 ~ 9:00)',
          '일요일 (오전 8:00 ~ 10:00)',
        ].map((e, idx) =>
          <RecruitMultiSelectButton
            isActivated={recruitInfoDataValue.availableDay.includes(e)}
            onClick={() => onDayButtonClicked(e)} key={idx} text={e} />)}

        {/* 중급자 경우 */}
        {recruitInfoDataValue.trackType === 'INTERMEDIATE' && [
          "목요일 성신여대 (오후 7:00 ~ 9:00)",
          "목요일 서울대입구 (오후 7:00 ~ 9:00)",
          "금요일 영등포구청 (오후 7:00 ~ 9:00)",
          "금요일 서울대입구 (오후 7:00 ~ 9:00)",
          '토요일 뉴욕 (오후 7:00 ~ 9:00)'
        ].map((e, idx) =>
          <RecruitMultiSelectButton
            isActivated={recruitInfoDataValue.availableDay.includes(e)} onClick={() => onDayButtonClicked(e)} key={idx} text={e} />)}

        <h2 style={{ paddingLeft: "8px" }} className='recruit-page-small-information'>*레슨 시간은 추후 조정될 예정입니다.</h2>
        <RecruitMidSizeButton deactivated={recruitInfoDataValue.availableDay.length === 0}
          onClick={onNextButtonClicked} text='다음' isBottomButton />
      </div>
    </RecruitPageWrapper>
  )
}

export default RecruitSelectAvailableDayPage