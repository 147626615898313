import React from 'react'
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router';

interface IRecruitPageWrapperProps {
  noBackButton?: boolean;
  children: React.ReactNode;
}
const RecruitPageWrapper = (props: IRecruitPageWrapperProps) => {
  const navigate = useNavigate();
  const onBackButtonClicked = () => {
    navigate(-1);
  }
  return (
    <div className='recruit-page-wrapper'>
      <div className='recruit-page-wrapper-nav-blank' />
      <div className='recruit-page-wrapper-nav'>
        {!props.noBackButton && < ArrowLeftOutlined onClick={onBackButtonClicked} />}
      </div>
      {props.children}
    </div>
  )
}

export default RecruitPageWrapper