import React from 'react';

import LinkTreePage from './LinkTree/LinkTreePage';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecruitSelectTypePage from './Recruit/RecruitSelectTypePage';
import RecruitSimpleFormPage from './Recruit/RecruitSimpleFormPage';
import RecruitDetailFormPage from './Recruit/RecruitDetailFormPage';
import RecruitSelectTrackPage from './Recruit/RecruitSelectTrackPage';
import RecruitSelectAvailableDayPage from './Recruit/RecruitSelectAvailableDayPage';
import RecruitSelectFavoriteDayPage from './Recruit/RecruitSelectFavoriteDayPage';
import RecruitCheckAllPage from './Recruit/RecruitCheckAllPage';
import RecruitDonePage from './Recruit/RecruitDonePage';
import RecruitAlumniDone from './Recruit/RecruitAlumniDone';
import BackdropLoading from './BackdropLoading';
import RecruitCurrentResultPage from './Recruit/RecruitCurrentResultPage';
import RouterLocationWrapper from './RouterLocationWrapper';

function App() {
  return (
    <RecoilRoot>
      <BackdropLoading />
      <BrowserRouter>
        <RouterLocationWrapper>
          <Routes>
            <Route path="/" element={<LinkTreePage />} />
            <Route path="/recruit/select-type" element={<RecruitSelectTypePage />} />
            <Route path="/recruit/simple-form" element={<RecruitSimpleFormPage />} />
            <Route path="/recruit/detail-form" element={<RecruitDetailFormPage />} />
            <Route path="/recruit/select-track" element={<RecruitSelectTrackPage />} />
            <Route path="/recruit/select-available-day" element={<RecruitSelectAvailableDayPage />} />
            <Route path="/recruit/select-favorite-day" element={<RecruitSelectFavoriteDayPage />} />
            <Route path="/recruit/check-all" element={<RecruitCheckAllPage />} />
            <Route path="/recruit/done" element={<RecruitDonePage />} />
            <Route path="/recruit/alumni-done" element={<RecruitAlumniDone />} />
            <Route path="/recruit/current-result" element={<RecruitCurrentResultPage />} />
            <Route path="*" element={<LinkTreePage />} />
          </Routes>
        </RouterLocationWrapper>
      </BrowserRouter>
    </RecoilRoot >
  );
}

export default App;
